import  { getApiUrl } from 'components/utils/helper';

  (function () {
    window.dataLayer = window.dataLayer || [];
    let originalPush = window.dataLayer.push;
    window.dataLayer.push = function () {
      let eventArgument = arguments[0];
      let eventName = eventArgument.event || eventArgument[0],
      eventData = eventArgument.event ? eventArgument : eventArgument[1];
      const mixPanelEventName = allowedEvents(eventName);
      if (mixPanelEventName !== eventName) {
        trackEvent(mixPanelEventName, eventData);
      }
      return originalPush.apply(window.dataLayer, arguments);
    };
  })();

    export const trackEvent = (eventName:string, eventData:object) => {
        //console.log('eventName: ', eventName);
        //console.log('eventData: ', eventData);
        const requestUrl = getApiUrl(null, null, 'tracking');
        const payload = formDataRequest(eventName, eventData);
        //console.log('payload: ', payload);
        const request: any = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        fetch(requestUrl, request)
            .then((res) => {
                return res.json();
            })
            .then(
                (result) => {
                    //console.log(result);
                },
                (error) => {
                    //console.log("Request error " + error);
                }
            );
    }


    const allowedEvents = (eventName: string) => {
        const eventMappings: { [key: string]: string } = {
            submit_form: 'form_submitted',
            page_view: 'page_viewed',
            begin_checkout: 'checkout_started',
            add_payment_info: 'payment_info_added',
            add_shipping_info: 'shipping_info_added',
            purchase: 'purchase_completed',
            progress_checkout: 'checkout_progressed',
            chapter_complete: 'chapter_completed',
            chapter_start: 'chapter_started',
            click_reaction: 'reaction_clicked',
            conversation_answer: 'conversation_answered',
            conversation_complete: 'conversation_completed',
            conversation_confidence: 'confidence_selected',
            conversation_feedback: 'feedback_displayed',
            conversation_view: 'conversation_viewed',
            conversation_start: 'conversation_started',
            episode_answer: 'episode_answered',
            episode_complete: 'episode_completed',
            episode_feedback: 'episode_feedback_displayed',
            episode_resume: 'episode_resumed',
            episode_start: 'episode_started',
            episode_view: 'episode_viewed',
            quiz_answer: 'quiz_answered',
            quiz_back: 'quiz_back_clicked',
            quiz_complete: 'quiz_completed',
            quiz_feedback: 'quiz_feedback_displayed',
            quiz_next: 'quiz_next_clicked',
            quiz_start: 'quiz_started',
            click: 'link_clicked',
            click_accordion: 'accordion_clicked',
            click_carousel: 'carousel_clicked',
            click_cta: 'cta_clicked',
            click_email: 'email_link_clicked',
            click_filter: 'filter_applied',
            click_footer_nav: 'footer_nav_clicked',
            click_header_nav: 'header_nav_clicked',
            click_menu_nav: 'menu_nav_clicked',
            click_phone: 'phone_link_clicked',
            click_search_result: 'search_result_clicked',
            click_sidebar_nav: 'sidebar_nav_clicked',
            click_tab: 'tab_clicked',
            click_tile: 'tile_clicked',
            close_chat: 'chat_closed',
            close_popup: 'popup_closed',
            dwell_on_content: 'content_dwelled',
            file_download: 'file_downloaded',
            scroll: 'page_scrolled',
            search: 'search_performed',
            share: 'content_shared',
            start_chat: 'chat_started',
            view_errors: 'errors_viewed',
            view_search_result: 'search_results_viewed',
            complete_form: 'form_completed',
            enter_field_form: 'form_field_entered',
            error_field: 'form_field_error_displayed',
            progress_form: 'form_progressed',
            start_form: 'form_started',
            login: 'user_logged_in',
            logout: 'user_logged_out',
            meta_data: 'meta_data_loaded',
            video_complete: 'video_completed',
            video_pause: 'video_paused',
            video_progress: 'video_progressed',
            video_resume: 'video_resumed',
            video_seek: 'video_seeked',
            video_start: 'video_started'
          };
        
          return eventMappings[eventName] || eventName;
    }

    const formDataRequest = (eventName: string, eventData: any) => {
      const fingerprint = getFingerprint();
      const trackingParams = getTrackingParams();

      // List of properties to exclude
      const excludeProps = [
        'gtm.element',
        'gtm.uniqueEventId',
        'gtm.oldUrl',
        'gtm.newUrl',
        'gtm.start',
        'gtm.scrollThreshold',
        'gtm.scrollUnits',
        'gtm.scrollDirection',
        'gtm.visibleRatio',
        'gtm.visibleTime',
        'gtm.elementClasses',
        'gtm.elementId',
        'gtm.elementTarget',
        'gtm.elementText',
        'gtm.elementUrl',
        'gtm.triggers',
        'gtm.willOpenInNewWindow',
        'eventTimeout'
      ];

      const eventDataProps = Object.keys(eventData)
        .filter(prop => prop !== 'event' && excludeProps.indexOf(prop) === -1 && typeof eventData[prop] !== 'function' && typeof eventData[prop] !== 'object')
        .reduce((props: { [key: string]: any }, prop) => {
          props[prop] = eventData[prop];
          return props;
        }, {});

      // Get the current state of the data layer
      let userProps: Record<string, unknown> = {};
      const { user } = getDataLayerState() || {};
      const { memberId, visitorIP, ...userData } = user || {};
      if (memberId) {
        userProps.memberId = memberId;
      }
      Object.entries(userData).forEach(([key, value]) => {
        if (key !== 'visitorIP') {
          userProps[`user_${key}`] = value;
        }
      });


      // Special handling for link clicks
      let linkClickProps = {};
      if (eventName === 'gtm.linkClick') {
        linkClickProps = {
          link_url: eventData['gtm.elementUrl'] || '',
          link_id: eventData['gtm.elementId'] || '',
          link_classes: eventData['gtm.elementClasses'] || '',
          link_target: eventData['gtm.elementTarget'] || '',
          link_text: eventData['gtm.elementText'] || '',
        };
      }

      return {
        event: eventName,
        properties: Object.assign({}, fingerprint, trackingParams, eventDataProps, linkClickProps, userProps, {
          page_title: document.title,
          $current_url: window.location.href,
          $referrer: document.referrer,
          mp_processing_time_ms: Date.now(),
          ip: "0"
        })
      };
    }

    const getFingerprint = () => {
      const ua = navigator.userAgent,
        browserInfo = getBrowserInfo(ua),
        osInfo = getOSInfo(ua),
        deviceType = getDeviceType(ua),
        referrerInfo = getReferrerInfo();

      return {
        user_agent: ua,
        $browser: browserInfo.name,
        $browser_version: browserInfo.version,
        $os: osInfo.name,
        $os_version: osInfo.version,
        device_type: deviceType,
        $screen_width: window.innerWidth,
        $screen_height: window.innerHeight,
        platform: navigator.platform || ua.toLowerCase().includes('mac') ? 'Mac' : (ua.toLowerCase().includes('win') ? 'Windows' : 'Other'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenResolution: window.innerWidth + 'x' + window.innerHeight,
        deviceMemory: (navigator as any).deviceMemory,
        hardwareConcurrency: navigator.hardwareConcurrency,
        doNotTrack: navigator.doNotTrack,
        colorDepth: window.screen.colorDepth,
        timezoneOffset: new Date().getTimezoneOffset(),
        language: navigator.language,
        $initial_referrer: referrerInfo.initial_referrer,
        $initial_referring_domain: referrerInfo.initial_referring_domain,
        $referring_domain: referrerInfo.referring_domain,
        $search_engine: referrerInfo.search_engine,
        mp_keyword: referrerInfo.mp_keyword
      };
    }

    const getBrowserInfo = (ua: string) => {
        const browserRegexes = [
            {name: 'Edge', regex: /Edg\/([\d\.]+)/},
            {name: 'Opera', regex: /OPR\/([\d\.]+)/},
            {name: 'Chrome', regex: /Chrome\/([\d\.]+)/},
            {name: 'Firefox', regex: /Firefox\/([\d\.]+)/},
            {name: 'Safari', regex: /Version\/([\d\.]+).*Safari/},
            {name: 'Internet Explorer', regex: /(?:MSIE |rv:)([\d\.]+)/}
          ];
      
          for (let i = 0; i < browserRegexes.length; i++) {
            const match = ua.match(browserRegexes[i].regex);
            if (match) {
              return {name: browserRegexes[i].name, version: match[1]};
            }
          }
      
          return {name: 'Unknown', version: ''};
    }

    const getOSInfo = (ua: string) => {
      const osRegexes = [
        { name: 'Android', regex: /Android ([\d\.]+)/ },
        { name: 'iOS', regex: /OS ([\d_]+) like Mac OS X/ },
        { name: 'Windows', regex: /Windows NT ([\d\.]+)/ },
        { name: 'macOS', regex: /Mac OS X ([\d_]+)/ },
        { name: 'Linux', regex: /Linux/ }
      ];

      for (let i = 0; i < osRegexes.length; i++) {
        const match = ua.match(osRegexes[i].regex);
        if (match) {
          return { name: osRegexes[i].name, version: match[1] ? match[1].replace(/_/g, '.') : '' };
        }
      }

      return { name: 'Unknown', version: '' };
    }

    const getDeviceType = (ua: string) => {
      switch (true) {
        case /mobile/i.test(ua):
          return 'Mobile';
        case /tablet/i.test(ua):
          return 'Tablet';
        default:
          return 'Desktop';
      }
    }

    const getReferrerInfo = () => {
      const extractDomain = (url: string) => {
        if (!url) return '';
        let domain = url.split('/')[2];
        return domain ? domain.replace('www.', '') : '';
      }
      const currentReferrer = document.referrer,
      initialReferrer = sessionStorage.getItem('initial_referrer') || currentReferrer,
      initialReferringDomain = sessionStorage.getItem('initial_referring_domain') || extractDomain(initialReferrer);
  
      if (!sessionStorage.getItem('initial_referrer')) {
        sessionStorage.setItem('initial_referrer', initialReferrer);
        sessionStorage.setItem('initial_referring_domain', initialReferringDomain);
      }
  
      const searchEngines: { [key: string]: string } = {
        'google.com': 'q',
        'bing.com': 'q',
        'yahoo.com': 'p',
        'duckduckgo.com': 'q'
        // Add more search engines as needed
      };
  
      const referringDomain = extractDomain(currentReferrer);
      let searchEngine = '', mpKeyword = '';
  
      for (let domain in searchEngines) {
        if (referringDomain.indexOf(domain) !== -1) {
          searchEngine = domain;
          const searchParams = new URLSearchParams(new URL(currentReferrer).search);
          mpKeyword = searchParams.get(searchEngines[domain]) || '';
          break;
        }
      }
  
      return {
        initial_referrer: initialReferrer,
        initial_referring_domain: initialReferringDomain,
        referring_domain: referringDomain,
        search_engine: searchEngine,
        mp_keyword: mpKeyword
      };
    }

    const getTrackingParams = () => {
      // List of UTM and marketing parameters to track
      const trackingParams = [
        'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content',
        'fbclid', 'gclid', 'msclkid', 'dclid', 'twclid', 'li_fat_id'
      ];
      let params: { [key: string]: string } = {};
      const storedParams = JSON.parse(sessionStorage.getItem('trackingParams') || '{}');
      const urlParams = new URLSearchParams(window.location.search);
      let hasNewParams = false;
  
      for (let i = 0; i < trackingParams.length; i++) {
        const param = trackingParams[i];
        let value = urlParams.get(param);
        if (urlParams.has(param) && value) {
          params[param] = value;
          hasNewParams = true;
        }
      }
  
      if (hasNewParams) {
        sessionStorage.setItem('trackingParams', JSON.stringify(params));
        return params;
      } else if (Object.keys(storedParams).length) {
        return storedParams;
      }
  
      return {};
    }

    const getDataLayerState = () => {
      return window.dataLayer?.reduce((state: { [key: string]: any }, obj: { [key: string]: any }) => {
        Object.assign(state, obj);
        return state;
      }, {});
    }
